import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function NavigationBar() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  return (
    <Box ref={ref}>
      <CssBaseline />
      <Paper
        sx={{
          position: "static",
          bottom: 0,
          color: "default",
          elevation: 3,
          zIndex: 5,
        }}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{ background: "#555555" }}
        >
          {/* <BottomNavigationAction icon={<FavoriteIcon color="action" />} /> */}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
