import * as React from "react";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

export default function FullScreenViewer (props) {
  const markerData = props.selectedMarker.data;
  const handleNext = props.handleNext;
  const handlePrevious = props.handlePrevious;
  const handleExitFullscreen = props.handleExitFullscreen;


  return (
    <Box className="fullscreen-image-wrapper">
      <img src={markerData.relPath} alt={markerData.title} />

      {/* Next button  */}
      <IconButton
        aria-label="Next"
        onClick={handleNext}
        size="large"
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.grey[300],
          },
          position: "absolute",
          right: 4,
          top: "50%",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ArrowForwardIosRoundedIcon fontSize="inherit" />
      </IconButton>

      {/* Previous button  */}
      <IconButton
        aria-label="Previous"
        onClick={handlePrevious}
        size="large"
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.grey[300],
          },
          position: "absolute",
          left: 4,
          top: "50%",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ArrowBackIosRoundedIcon fontSize="inherit" />
      </IconButton>

      {/* Exit fullScreen button  */}
      {markerData.mtype === 0 && (
        <IconButton
          aria-label="Exit Full Screen"
          onClick={handleExitFullscreen}
          size="large"
          sx={{
            "&:hover": {},
            position: "absolute",
            right: 4,
            bottom: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FullscreenExitIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};
