import React, { useState, useRef, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    textAlign: "center",
  },
  imgBox: {
    maxWidth: "100%",
    maxHeight: "80%",
    //margin: "1px",
  },
  img: {
    height: "inherit",
    maxWidth: "inherit",
  },
  input: {
    display: "block",
    color: "white",
    borderColor: "default",
  },
  root: {
    "& .MuiFormLabel-root": {
      color: "grey", // or black
    },
  },
}));

const theme = createTheme({
  palette: {
    neutral: {
      main: "#555555",
      contrastText: "#fff",
    },
    highlight: {
      main: "#aaaaaa",
      contrastText: "#fff",
    },
  },
});

const StyledTextField = styled(TextField)`
  background: black;
  & label.Mui-focused {
    color: black;
  }
  & .MuiInput-underline:after {
    border-bottom-color: black;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: black;
    }
    &:hover fieldset {
      border-color: black;
    }
    &.Mui-focused fieldset {
      border-color: black;
    }
  }
`;

export default function MediaDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [mediaUrl, setMediaUrl] = useState(props.mediaUrl);
  const [isUploading, setIsUploading] = useState(false);
  const mediaType = props.mediaType;
  const mediaSize = props.mediaSize;
  const classes = useStyles();
  const captionRef = useRef("");

  const handlePost = props.handlePost;
  const handleDialogClose = props.handleClose;

  const handlePostClick = () => {
    setIsUploading(true);
    handlePost(captionRef.current.value);
  };

  const handleClose = () => {
    setIsUploading(false);
    setMediaUrl(null);
    setOpen(false);
    handleDialogClose();
  };

  const onImageLoad = useCallback(({ target: img }) => {
    // TODO resize image if it's too large
    // console.log('img dimensions=', img.naturalWidth, ',', img.naturalHeight);
  });

  const onVideoMetadataLoad = useCallback(({ target }) => {
    // TODO resize/shorten video if it's too large
    // console.log('video duration and dimensions=', target.duration, ',', target.videoWidth, ',', target.videoHeight);
  });

  const handleDownload = () => {};

  return (
    <div>
      {mediaUrl && (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          aria-labelledby="media-dialog-title"
          aria-describedby="media-dialog-description"
          maxWidth="xl"
          PaperProps={{
            style: {
              backgroundColor: "black",
              // backgroundColor: isUploading ? "transparent" : "black",
            },
          }}
        >
          <Container maxWidth="sm">
            {mediaUrl && (
              <Box
                display="flex"
                justifyContent="center"
                pt={3}
                className={classes.imgBox}
              >
                {mediaType === 1 && (
                  <video
                    controls
                    src={mediaUrl}
                    alt={"captured media"}
                    className={classes.img}
                    onLoadedMetadata={onVideoMetadataLoad}
                  ></video>
                )}
                {mediaType === 0 && (
                  <img
                    src={mediaUrl}
                    alt={"captured media"}
                    className={classes.img}
                    onLoad={onImageLoad}
                  ></img>
                )}
              </Box>
            )}
            <br />
            <div>
              <StyledTextField
                id="media-caption"
                label="Caption..."
                placeholder="Caption..."
                size="small"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={3}
                inputRef={captionRef}
                className={classes.root}
                inputProps={{ className: classes.input }}
              />
            </div>
            <br />
            <ThemeProvider theme={theme}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isUploading && <CircularProgress color="highlight" />}
              </Box>
            </ThemeProvider>
          </Container>

          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button
                href={mediaUrl}
                download="test-download.png"
                onClick={handleDownload}
                color="neutral"
              >
                Keep
              </Button>
              <Button onClick={handleClose} color="neutral">
                Cancel
              </Button>
              {isUploading && <Button color="neutral">Post</Button>}
              {!isUploading && (
                <Button onClick={handlePostClick} color="highlight" autoFocus>
                  Post
                </Button>
              )}
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
