import React, { useRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import PhotoCameraRoundedIcon from "@mui/icons-material/PhotoCameraRounded";
import VideoCameraBackRoundedIcon from "@mui/icons-material/VideoCameraBackRounded";
import EventIcon from "@mui/icons-material/Event";
import { isMobile } from "react-device-detect";
import MediaDialog from "./MediaDialog";
import { latLongToQuadKey } from "./tileSystem";

const axios = require("axios").default;
const baseUrl = "https://api.ubk2.com/";

const pointZero = {
  lat: 0,
  lng: 0,
};

const pointOne = {
  lat: 1,
  lng: 1,
};

let watcherIdentifier = null;

export default function PostMediaUI(props) {
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaType, setMediaType] = useState(-1);
  const [mediaSize, setMediaSize] = useState(0);
  const [mediaTimestamp, setMediaTimestamp] = useState("");
  const [dialogInstanceKey, setDialogInstanceKey] = useState(0);
  const [videoInput, setVideoInput] = useState("");
  const [photoInput, setPhotoInput] = useState("");
  const [caption, setCaption] = useState("");
  const videoInputRef = useRef(null);
  const photoInputRef = useRef(null);
  const handleRefresh = props.handleRefresh;
  const [currentLocation, setCurrentLocation] = useState(null);
  const positionOptions = {
    enableHighAccuracy: true,
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      watcherIdentifier = navigator.geolocation.watchPosition(
        (position) => {
          let myLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(myLocation);
        },
        () => {
          handleLocationError(true);
        },
        positionOptions
      );
    } else {
      handleLocationError(false);
    }
  };

  const handlePhotoUploadClick = () => {
    if (!isMobile) {
      alert("Camera vailable on mobile only!");
      return;
    }
    handleDialogReset();
    setMediaUrl(null);
    getCurrentLocation();
    photoInputRef.current.click();
  };

  const handleVideoUploadClick = () => {
    if (!isMobile) {
      alert("Camera vailable on mobile only!");
      return;
    }
    handleDialogReset();
    setMediaUrl(null);
    getCurrentLocation();
    videoInputRef.current.click();
  };

  const handleDialogReset = () => setDialogInstanceKey((i) => i + 1);

  const resetInputs = () => {
    setVideoInput("");
    setPhotoInput("");
  };

  const handleCapture = (target, mediaType) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setMediaFile(file);
        setMediaUrl(newUrl);
        setMediaType(mediaType);
        setMediaSize(file.size);
        var timestamp = new Date(file.lastModified).toISOString();
        setMediaTimestamp(timestamp);
        if (watcherIdentifier) {
          navigator.geolocation.clearWatch(watcherIdentifier);
        }
      }
    }
    resetInputs();
  };

  // const PostEventAction = (props) => {
  //   // TODO Implement post event
  //   return (
  //     <React.Fragment>
  //       <SpeedDialAction
  //         icon={<EventIcon />}
  //         tooltipTitle="The feature will be available soon!"
  //         component="span"
  //         {...props}
  //       ></SpeedDialAction>
  //     </React.Fragment>
  //   );
  // };

  const closeMediaDialog = useCallback(() => {
    if (watcherIdentifier) {
      navigator.geolocation.clearWatch(watcherIdentifier);
    }
  });

  const postMedia = useCallback((caption) => {
    if (!currentLocation) {
      alert("currentLocation is null!");
      return;
    }
    try {
      let quadKeyBase4 = latLongToQuadKey(currentLocation, 16);
      let quadKey = parseInt(quadKeyBase4, 4);
      let body = [
        {
          "Content-Type": "text/json",
          data: "",
        },
      ];

      let mediaItem = null;
      let transactionId = "";
      let mid = -1;
      let url = "";
      var api = "api/transaction/start?mc=1";
      var command = "post";
      setCaption(caption);

      axios
        .post(baseUrl + api, body)
        .then(function (response) {
          transactionId = response.data.transactionId;
          mediaItem = response.data.mediaItems[0];
          mid = mediaItem.mid;
          url = baseUrl + mediaItem.putURL;
          command = "put";
          return axios.put(url, mediaFile, {
            headers: { "Content-Type": "application/octet-stream" },
          });
        })
        .then(function (response) {
          command = "post";
          api = "api/transaction/publish";
          body = {
            transactionId: transactionId,
            events: [
              {
                images: [mid],
                title: caption,
                datetimeCaptured: mediaTimestamp,
                lat: currentLocation.lat,
                long: currentLocation.lng,
                quadkey: quadKey,
                ownerId: 1,
                coverPageId: mid,
                etype: 0,
              },
            ],
          };
          return axios.post(baseUrl + api, body);
        })
        .then(function (response) {
          handleRefresh();
          setMediaUrl(null);
        })
        .catch(function (err) {
          alert(
            "Axios Error",
            err,
            ", Command =",
            command,
            ", API =",
            api,
          );
        });
    } catch (err) {
      alert("Uncaught Error: " + err);
      setMediaUrl(null);
    } finally {
      if (watcherIdentifier) {
        navigator.geolocation.clearWatch(watcherIdentifier);
      }
    }
  });

  return (
    <div>
      <input
        accept="image/*"
        ref={photoInputRef}
        value={photoInput}
        style={{ display: "none" }}
        id="post-photo"
        type="file"
        capture="environment"
        onChange={(e) => handleCapture(e.target, 0)}
      />
      <input
        accept="video/*"
        ref={videoInputRef}
        value={videoInput}
        style={{ display: "none" }}
        id="post-video"
        type="file"
        capture="environment"
        onChange={(e) => handleCapture(e.target, 1)}
      />

      <Box sx={{ height: 320, flexGrow: 1 }}>
        <Fab
          sx={{
            position: "fixed",
            zIndex: 2,
            bottom: 85,
            right: 12,
          }}
          ariaLabel="Post Photo"
          onClick={handlePhotoUploadClick}
          size="medium"
          style={{ background: "#eeeeee" }}
        >
          <PhotoCameraRoundedIcon />
        </Fab>

        <Fab
          sx={{
            position: "fixed",
            zIndex: 2,
            bottom: 30,
            right: 12,
          }}
          ariaLabel="Post Video"
          onClick={handleVideoUploadClick}
          size="medium"
          style={{ background: "#eeeeee" }}
        >
          <VideoCameraBackRoundedIcon />
        </Fab>
      </Box>

      {mediaUrl && currentLocation && (
        <MediaDialog
          key={dialogInstanceKey}
          handlePost={postMedia}
          handleClose={closeMediaDialog}
          mediaUrl={mediaUrl}
          mediaType={mediaType}
          mediaSize={mediaSize}
        />
      )}
    </div>
  );
}

function handleLocationError(browserHasGeolocation) {
  let errorMessage = browserHasGeolocation
    ? "Error: Location Services is disabled on your device! You cannot post photos and videos."
    : "Error: Your browser doesn't support geolocation! You cannot post photos and videos.";
  alert(errorMessage);
}
