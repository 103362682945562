import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";

const StyledPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.subtitle1,
  textAlign: "left",
  color: theme.palette.text.secondary,
  lineHeight: "20px",
  left: 6,
}));

export default function MediaViewer (props) {
  const markerData = props.selectedMarker.data;
  const handleClose = props.handleClose;
  const handleNext = props.handleNext;
  const handlePrevious = props.handlePrevious;
  const handleFullscreen = props.handleFullscreen;

  let width;
  let height;
  let arrowsTop = "40%";

  const getScreenOrientation = () => {
    // no support for landscape mode if the window.screen.orientation feature is not supported (current Safari)
    // if (
    //   window.screen.orientation &&
    //   (window.screen.orientation.type === "landscape-primary" ||
    //     window.screen.orientation.type === "landscape-secondary")
    // ) {
    //   return 1;
    // }
    return 0;
  };

  const [screenOrientation, setScreenOrientation] = React.useState(
    getScreenOrientation()
  );
  const [screenDimensions, setScreenDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
    ar: window.innerWidth / window.innerHeight,
  });

  // if (window.screen.orientation) {
  //   window.screen.orientation.onchange = function (e) {
  //     setScreenOrientation(getScreenOrientation());
  //     setScreenDimensions({
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //       ar: window.innerWidth / window.innerHeight,
  //     });
  //   };
  // }

  // Handling resize is expensive and benefits the desktop only
  // React.useEffect(() => {
  //   function handleResize() {
  //     setDimensions({
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //       ar: window.innerWidth / window.innerHeight,
  //     });
  //   }
  //   window.addEventListener("resize", handleResize);
  // });
  
  width = screenDimensions.width;
  height = (screenDimensions.height * 3) / 6;
 
  return (
    <Box
      sx={{
        borderRadius: "5px",
      }}
      boxShadow={3}
      width={width}
      height={height}
      position="relative"
    >
      {markerData.mtype === 1 && (
        <video
          src={markerData.relPath}
          alt={markerData.title}
          controls
          autoPlay
          muted
          style={{
            width: width,
            height: height,
            objectFit: "cover",
          }}
        ></video>
      )}

      {markerData.mtype === 0 && (
        <img
          src={markerData.relPath}
          alt={markerData.title}
          style={{
            width: width,
            height: height,
            objectFit: "cover",
          }}
        />
      )}

      <StyledPaper
        sx={{
          position: "absolute",
          opacity: 0.75,
          top: 8,
        }}
      >
        {markerData.timecaptured}
      </StyledPaper>

      <StyledPaper
        sx={{
          position: "absolute",
          opacity: 0.75,
          top: 30,
        }}
      >
        {markerData.title}
      </StyledPaper>

      {/* Close button */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="large"
        sx={{
          position: "absolute",
          right: 1,
          top: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>

      {/* Next button */}
      <IconButton
        aria-label="Next"
        onClick={handleNext}
        size="large"
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.grey[300],
          },
          position: "absolute",
          right: 1,
          top: arrowsTop,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ArrowForwardIosRoundedIcon fontSize="inherit" />
      </IconButton>

      {/* Previous button */}
      <IconButton
        aria-label="Previous"
        onClick={handlePrevious}
        size="large"
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.grey[300],
          },
          position: "absolute",
          left: 1,
          top: arrowsTop,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ArrowBackIosRoundedIcon fontSize="inherit" />
      </IconButton>

      {/* Full-screen button */}
      {markerData.mtype === 0 && (
        <IconButton
          aria-label="Full Screen"
          onClick={handleFullscreen}
          size="large"
          sx={{
            "&:hover": {
              color: (theme) => theme.palette.grey[300],
            },
            position: "absolute",
            right: 1,
            bottom: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FullscreenRoundedIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};