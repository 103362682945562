import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactPlayer from "react-player/lazy";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { formatDistance, parseISO } from "date-fns";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import "./App.css";
import NavigationBar from "./NavigationBar";
import PostMediaUI from "./PostMediaUI";
import SearchBar from "./SearchBar";
import MediaViewer from "./MediaViewer";
import FullScreenViewer from "./FullscreenViewer";

import { latLongToQuadKey, clip } from "./tileSystem";
import { flexbox } from "@mui/system";
import { red } from "@mui/material/colors";

const axios = require("axios").default;
const baseUrl = "https://api.ubk2.com/";
const timeParam = "stime=2022-07-01T00:00:00Z";

const mediaUrl =
  "https://ubikitu.s3.us-west-2.amazonaws.com/public-media/mixkit-times-square.mp4";
const markerIconUrl =
  "https://ubikitu.s3.us-west-2.amazonaws.com/public-media/mixkit-times-square-2.png";

const containerStyle = {
  width: "100%",
  height: "100%",
};
const defaultZoom = 15;
const defaultCenter = {
  lat: 49.28273,
  lng: -123.120735,
};
const fetchedKeys = new Map();
const markerExpiry = 60 * 5; // 5 minutes
const visibleMarkers = new Map();
let selectedMarker = null;

const styles = {
  cleancut: [
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          // color: "#D1D1B8",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          saturation: 50,
        },
        {
          lightness: 75,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#C6E2FF",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 75,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#C5E3BF",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "labels",
      stylers: [{ visibility: "simplified" }],
    },

    {
      featureType: "administrative.neighborhood",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

const options = {
  disableDefaultUI: true,
  styles: styles["cleancut"],
  clickableIcons: false,
  gestureHandling: "greedy",
  keyboardShortcuts: false,
};

const libraries = ["places"];

export default function App() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // selected is just used to enforce a re-render of the media viewer.
  // selectedMarker is used to track the selected marker, since setSelected
  // does not immediately change the selected value.
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        deselectMarker();
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  const calculateMediaDimensions = useCallback((data) => {
    if (data.mtype === 0) {
      const image = new Image();
      image.onload = () => {
        setMediaAspectRatio(image.naturalWidth / image.naturalHeight);
      };
      image.src = data.relPath;
    } else if (data.mtype === 1) {
      const video = document.createElement("video");
      video.onloadedmetadata = (event) => {
        const vid = event.path[0];
        setMediaAspectRatio(vid.videoWidth / vid.videoHeight);
      };
      video.src = data.relPath;
    }
  });

  const [mapInstance, setMapInstance] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(defaultCenter);
  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let myLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(myLocation);
          panTo(myLocation);
        },
        () => {
          handleLocationError(true, defaultCenter);
        }
      );
    } else {
      handleLocationError(false, defaultCenter);
    }
  }, [mapInstance]);

  const [refresh, setRefresh] = useState(1);
  const [forceRefresh, setForceRefresh] = useState(true);
  const refreshMarkers = useCallback(() => {
    setRefresh(refresh + 1);
  });

  const forceRefreshMarkers = useCallback(() => {
    setRefresh(refresh + 1);
    setForceRefresh(true);
  });

  function createMarker(mapInstance, data) {
    const latDirection = Math.random() >= 0.5 ? 1 : -1;
    const lngDirection = Math.random() >= 0.5 ? 1 : -1;
    data.lat += Math.random() * 0.00001 * latDirection;
    data.long += Math.random() * 0.00001 * lngDirection;
    let utcTime = data.timecaptured + "Z";
    data.timecaptured = formatDistance(parseISO(utcTime), new Date(), {
      addSuffix: true,
    });
    let marker = new window.google.maps.Marker({
      key: data.id,
      position: {
        lat: data.lat,
        lng: data.long,
      },
      title: data.title,
      opacity: 0.75,
      icon: {
        url: `./media.png`,
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(14, 35),
        scaledSize: new window.google.maps.Size(16, 20),
      },
      data: data,
    });
    marker.setMap(mapInstance);

    marker.addListener("click", () => {
      calculateMediaDimensions(data);
      if (selectedMarker && selectedMarker != marker) {
        selectedMarker.setOpacity(0.5);
      }
      selectMarker(marker);
    });
    return marker;
  }

  const selectMarker = (marker) => {
    selectedMarker = marker;
    marker.setIcon({
      url: `media-selected.png`,
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 35),
      scaledSize: new window.google.maps.Size(16, 20),
    });
    marker.setOpacity(1);
    setSelected(marker);
  };

  const deselectMarker = () => {
    if (selectedMarker) {
      selectedMarker.setOpacity(0.5);
    }
    selectedMarker = null;
    setSelected(null);
  };

  const [markers, setMarkers] = useState(new Map());
  const [markersArray, setMarkersArray] = useState([]);

  // Get events from the server
  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    const zoomLevel = clip(mapZoomLevel, 5, 10);

    const quadKey = latLongToQuadKey(mapCenter, zoomLevel);
    const currentTime = parseInt((new Date().getTime() / 1000).toFixed(0));
    for (const [key, value] of fetchedKeys) {
      if (currentTime - value > markerExpiry) {
        fetchedKeys.delete(key);
        continue;
      }
      if (quadKey.startsWith(key)) {
        if (!forceRefresh) {
          return;
        }
      }
      if (key.startsWith(quadKey)) {
        fetchedKeys.delete(key);
        continue;
      }
    }
    let fetchSucceeded = false;
    setForceRefresh(false);
    fetchedKeys.set(quadKey, currentTime);
    let params = "?" + timeParam + "&qk=" + quadKey;
    axios
      .get(baseUrl + "api/elist" + params)
      .then(function (response) {
        fetchSucceeded = true;
        response.data.map((data) => {
          if (!markers.has(data.id)) {
            let marker = createMarker(mapInstance, data);
            markers.set(data.id, marker);
          }
        });
        setMarkersArray(Array.from(markers.values()));
      })
      .catch(function (err) {
        if (fetchSucceeded) {
          alert(
            "Axios GET succeeded but something went wrong with creating markers!"
          );
        } else {
          alert("axios GET error!", err);
        }
      });
  }, [refresh]);

  const [visibleMarkersArray, setVisibleMarkersArray] = useState([]);
  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    // Refresh visible markers
    let bounds = mapInstance.getBounds();
    for (const [key, marker] of visibleMarkers) {
      if (!bounds.contains(marker.getPosition())) {
        visibleMarkers.delete(key);
      }
    }
    for (const [key, marker] of markers) {
      if (bounds.contains(marker.getPosition()) && !visibleMarkers.has(key)) {
        visibleMarkers.set(key, marker);
        if (!selectedMarker) {
          setSelected(marker);
          selectedMarker = marker;
        }
      }
    }
    setVisibleMarkersArray(Array.from(visibleMarkers.keys()));
  }, [markersArray]);

  const handleClose = useCallback(() => {
    deselectMarker(selectedMarker);
  });

  const handleNext = useCallback(() => {
    let currentIndex = visibleMarkersArray.indexOf(selectedMarker.key);
    if (
      visibleMarkersArray.length > 1 &&
      currentIndex < visibleMarkersArray.length - 1
    ) {
      currentIndex++;
      deselectMarker(selectedMarker);
      selectMarker(visibleMarkers.get(visibleMarkersArray[currentIndex]));
    }
  });

  const handlePrevious = useCallback(() => {
    let currentIndex = visibleMarkersArray.indexOf(selectedMarker.key);
    if (visibleMarkersArray.length > 1 && currentIndex > 0) {
      currentIndex--;
      deselectMarker(selectedMarker);
      selectMarker(visibleMarkers.get(visibleMarkersArray[currentIndex]));
    }
  });

  const handleFullscreen = useCallback(() => {
    setFullScreenMode(true);
  });

  const handleExitFullscreen = useCallback(() => {
    setFullScreenMode(false);
  });

  const onMapLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  const panTo = useCallback(
    (position, zoom = defaultZoom) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.panTo(position);
      mapInstance.setZoom(zoom);
    },
    [mapInstance]
  );

  // TODO try onBoundsChanged as a replacement for both center an zoom level change
  const [mapCenter, setMapCenter] = useState(currentLocation);
  const onMapCenterChanged = useCallback(() => {
    if (!mapInstance) {
      return;
    }
    setMapCenter(mapInstance.getCenter().toJSON());
    refreshMarkers();
  });

  const [mapZoomLevel, setMapZoomLevel] = useState(defaultZoom);
  const onMapZoomChanged = useCallback(() => {
    if (!mapInstance) {
      return;
    }
    setMapZoomLevel(mapInstance.getZoom());
    refreshMarkers();
  });

  const [mediaAspectRatio, setMediaAspectRatio] = React.useState(1);
  const [fullScreenMode, setFullScreenMode] = useState(false);

  if (loadError) return "Error loading Maps!";
  if (!isLoaded) return "Loading Maps...";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
      }}
    >
      {selected ? (
        <MediaViewer
          selectedMarker={selectedMarker}
          handleClose={handleClose}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleFullscreen={handleFullscreen}
        />
      ) : null}
      {selected && fullScreenMode ? (
        <FullScreenViewer
          selectedMarker={selectedMarker}
          handleClose={handleClose}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleExitFullscreen={handleExitFullscreen}
        />
      ) : null}

      <div
        style={{
          display: "inline-block",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "block",
            height: "100%",
          }}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={currentLocation}
            zoom={defaultZoom}
            options={options}
            onLoad={onMapLoad}
            onCenterChanged={onMapCenterChanged}
            onZoomChanged={onMapZoomChanged}
          >
            {!fullScreenMode && (
              <PostMediaUI handleRefresh={forceRefreshMarkers} />
            )}
          </GoogleMap>
        </div>

        <div style={{ position: "absolute", top: "0", width: "100%" }}>
          <SearchBar panTo={panTo} />
        </div>
      </div>
      <NavigationBar />
    </div>
  );
}

function handleLocationError(browserHasGeolocation, position) {
  let errorMessage = browserHasGeolocation
    ? "Error: Location Services is disabled on your device!"
    : "Error: Your browser does not support geolocation!";
  alert(errorMessage);
}
