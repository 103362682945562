// // Mercator Projection functions
const earthRadius = 6378137;
const minLatitude = -85.05112878;
const maxLatitude = 85.05112878;
const minLongitude = -180;
const maxLongitude = 180;

export const clip = (num, min, max) => Math.min(Math.max(num, min), max);
const getMapSize = (levelOfDetail) => 256 << levelOfDetail;
const groundResolution = (latitude, levelOfDetail) => {
  latitude = clip(latitude, minLatitude, maxLatitude);
  return (
    (Math.cos((latitude * Math.PI) / 180) * 2 * Math.PI * earthRadius) /
    getMapSize(levelOfDetail)
  );
};
const mapScale = (latitude, levelOfDetail, screenDpi) => {
  return (groundResolution(latitude, levelOfDetail) * screenDpi) / 0.0254;
};

const latLongToPixelXY = (latLong, levelOfDetail) => {
  let latitude = clip(latLong.lat, minLatitude, maxLatitude);
  let longitude = clip(latLong.lng, minLongitude, maxLongitude);
  let x = (longitude + 180) / 360;
  let sinLatitude = Math.sin((latitude * Math.PI) / 180);
  let y = 0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * Math.PI);

  let mapSize = getMapSize(levelOfDetail);
  let pixelX = clip(x * mapSize + 0.5, 0, mapSize - 1);
  let pixelY = clip(y * mapSize + 0.5, 0, mapSize - 1);
  return {
    x: pixelX | 0,
    y: pixelY | 0,
  };
};

const pixelXYToLatLong = (pixelXY, levelOfDetail) => {
  let mapSize = getMapSize(levelOfDetail);
  let x = clip(pixelXY.x, 0, mapSize - 1) / mapSize - 0.5;
  let y = 0.5 - clip(pixelXY.y, 0, mapSize - 1) / mapSize;
  let latitude = 90 - (360 * Math.atan(Math.exp(-y * 2 * Math.PI))) / Math.PI;
  let longitude = 360 * x;
  return {
    lat: latitude,
    lng: longitude,
  };
};

const pixelXYToTileXY = (pixelXY) => {
  return {
    x: (pixelXY.x / 256) | 0,
    y: (pixelXY.y / 256) | 0,
  };
};

const tileXYToPixelXY = (tileXY) => {
  return {
    x: tileXY.x * 256,
    y: tileXY.y * 256,
  };
};

const tileXYToQuadKey = (tileXY, levelOfDetail) => {
  let quadKey = "";
  for (let i = levelOfDetail; i > 0; i--) {
    let digit = "0",
      mask = 1 << (i - 1);
    if ((tileXY.x & mask) !== 0) {
      digit++;
    }

    if ((tileXY.y & mask) !== 0) {
      digit++;
      digit++;
    }
    quadKey += digit;
    //console.log('tileXYToQuadKey, i =', i, ', digit =', digit, ', quadKey =', quadKey);
  }
  return quadKey;
};

export const latLongToQuadKey = (latLong, levelOfDetail) => {
  // console.log( 'latLongToQuadKey, latLong =', latLong, ', levelOfDetail =', levelOfDetail);
  const pixelXY = latLongToPixelXY(latLong, levelOfDetail);
  // console.log("pixelXY =", pixelXY);
  const tileXY = pixelXYToTileXY(pixelXY);
  // console.log("tileXY =", tileXY);
  return tileXYToQuadKey(tileXY, levelOfDetail);
};
